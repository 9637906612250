import React from "react"
import { graphql } from "gatsby"
import { Row, Col } from "antd"

import Bio from "../components/bio"
import Summary from "../components/summary"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.postsRemark.nodes

  // data.tagsGroup.group
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO />
        <Bio />
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} work="works">
      <SEO />
      <Bio />
      <p className="works">WORKS</p>
      <Row gutter={[24, 24]}>
        {posts.map(post => (
          <Col key={post.fields.slug} xl={8} lg={8} md={8} sm={12} xs={24}>
            <Summary post={post} />
          </Col>
        ))}
      </Row>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    postsRemark: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: "works" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          image
          name
          url
        }
      }
    }
    tagsGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
